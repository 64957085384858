
import Vue from 'vue'
import KidsFormModal from './Container.vue'

const Comp = Vue.extend(KidsFormModal)

export const showKidsFormModal = (params = {}) => {
  if (typeof window === 'undefined') {
    return
  }
  const { childInfo, language } = params
  const isModified = !!childInfo // 判断是编辑还是新增
  const instance = new Comp({ propsData: { childInfo, language, isModified } }).$mount()

  const destroy = () => {
    window.setTimeout(() => {
      instance.$destroy()
    }, 100)
  }

  return new Promise((resolve) => {
    instance.$on('submit', (data) => {
      // 新增/编辑
      resolve({ action: 'SAVE', data })
      destroy()
    })
    instance.$on('close', () => {
      // 无操作，关闭
      resolve({ action: 'CLOSE' })
      destroy()
    })
    instance.$on('delete-success', () => {
      // 删除
      resolve({ action: 'DELETE' })
      destroy()
    })
  })
}
