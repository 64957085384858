<script lang="jsx">
export default {
  name: 'TagDropdownContainer',
  functional: true,
  render(_, { props, slots }) {
    const { selected } = props
    const slotDefault = slots?.()?.default ?? null

    return (
      <div
        class={{
          'tag-dropdown__container': true,
          'tag-dropdown__container-selected': selected
        }}
      >
        { slotDefault }
      </div>
    )
  }
}
</script>
