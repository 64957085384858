<template>
  <div
    class="date-selector"
    :class="selectorCls"
    @click.stop="showDatePicker"
  >
    <div class="date-selector__label">
      {{ language.SHEIN_KEY_PWA_32968 }}
    </div>
    <div class="date-selector__content">
      <div
        v-if="month && year"
        class="date-selector__val"
      >
        {{ monthAMap[month] }} {{ year }}
      </div>
      <template v-else>
        {{ language.SHEIN_KEY_PWA_32892 }}
      </template>
    </div>
    <div class="date-selector__suffix-icon">
      <DateIcon />
    </div>
    <DatePicker
      :visible="pickerVisible"
      :month="month"
      :year="year"
      @select="handleSelect"
    />
  </div>
</template>

<script>

import { defineComponent, ref, inject, computed } from 'vue'

import DateIcon from './DateIcon.vue'
import DatePicker from './DatePicker.vue'

import { getMonthMap } from './constant'

export default defineComponent({
  name: 'DateSelector',
  components: {
    DateIcon,
    DatePicker,
  },
  props: {
    month: {
      type: [String, Number],
      default: '',
    },
    year: {
      type: [String, Number],
      default: '',
    },
  },
  setup (props, { emit }) {

    const { getLanguage } = inject('KIDS_CONTEXT')
    const { monthAMap } = getMonthMap(getLanguage())

    const {
      isBirthdayValid,
    } = inject('KIDS_FORM_VALIDATE', { isBirthdayValid: ref(true) })

    const pickerVisible = ref(false)

    const showDatePicker = () => {
      pickerVisible.value = true
    }

    const handleSelect = (data) => {
      pickerVisible.value = false
      if (data) {
        emit('select', data)
      }
    }

    const selectorCls = computed(() => {
      return {
        'date-selector__error': !isBirthdayValid.value,
      }
    })

    return {
      monthAMap,
      language: getLanguage(),
      selectorCls,
      showDatePicker,
      handleSelect,
      pickerVisible,
    }
  },
})
</script>

<style lang="less" scoped>
.date-selector {
  border: 1px solid #E5E5E5;
  height: 56px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  
  &__error {
    border-color: #BF4123;
  }

  &__label {
    color: #666;
    font-size: 12px;
    font-weight: 400;
  }

  &__content {
    color: #bbb;
    font-size: 14px;
    font-weight: 400;
  }

  &__val {
    color: #000;
  }

  &__suffix-icon {
    position: absolute;
    top: 20px;
    right: 12px;
  }
}
</style>
