import dayjs from 'dayjs'
import UserInfoManager from 'public/src/services/UserInfoManager'

const LOCALSTORAGE_KEY = 'kidTag_key'

// 获取唯一标识[memberid_pathname]
function getSoleKey () {
  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'RECOMMENDPOP/GETKEY' })
  return `${memberId || gbCommonInfo?.SiteUID}_${location.pathname}`
}

/**
 * kidTag 本地缓存
 * @returns  {
 *  [memberid_pathname]: {
 *    inster: time, // 插入时间
 *    popover: true | false // 是否已经展示popover
 *  }
 * }
 */
function getKidsTagLocalStorage () {  
  const cache = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) ?? '{}')
  return cache
}

function setKidsTagLocalStorage (cache) {  
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(cache))
}


export function getIsShowKidsTagPopoverByCache () {
  if (typeof window == 'undefined') return false

  const key = getSoleKey()
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache
  if (!cache.popover) {
    cache.popover = true
    setKidsTagLocalStorage(allCacheData)
    return true
  }

  return false
}/**
 * 将kids标签插入到标签列表中
 * @param {*} tagAllList 
 * @param {*} kidsTags 
 */
export function tagListInsertKids (tagAllList, kidsTags) {
  // ssr
  if (typeof window == 'undefined') {
    tagAllList.splice(0, 0, ...kidsTags)
    return  [...tagAllList]
  }

  const key = getSoleKey()
  const childLength = kidsTags?.[0]?.child_list?.length || 0
  const allCacheData = getKidsTagLocalStorage()
  const cache = allCacheData[key] ?? {}
  allCacheData[key] = cache

  // 首次进入
  if (!cache.inster) {
    cache.inster = Date.now()
    tagAllList.splice(0, 0, ...kidsTags)
    setKidsTagLocalStorage(allCacheData)
    return [...tagAllList]
  }

  // 有小孩信息
  if (childLength) {
    tagAllList.splice(0, 0, ...kidsTags)
    return [...tagAllList]
  }

  // 无小孩信息
  const nowDay = dayjs()
  const cacheDay = dayjs(cache.inster)
  // 超过7天
  if (nowDay.diff(cacheDay, 'day') >= 7) {
    cache.inster = Date.now()
    tagAllList.splice(0, 0, ...kidsTags)
    setKidsTagLocalStorage(allCacheData)
    return [...tagAllList]
  }
  
  // 超过24h - 标签云最后一位
  if (nowDay.diff(cacheDay, 'day') >= 1) {
    tagAllList.push(...kidsTags)
    return [...tagAllList]
  } 

  // 24h内
  tagAllList.splice(0, 0, ...kidsTags)
  return [...tagAllList]
}

// 获取 kids 对应的值
export const getKidValue = item => {
  const { sex } = item
  const nowDay = dayjs()
  const birthday = dayjs(item.birthday, 'YY-MM')
  const totalMonth = nowDay.diff(birthday, 'month')

  if (totalMonth <= 36) {
    return sex == 1 ? '600060714' : '600060717'
  } else if (totalMonth <= 84) {
    return sex == 1 ? '600060719' : '600060720'
  } else if (totalMonth <= 144) {
    return sex == 1 ? '600060724' : '600060725'
  } else if (totalMonth < 192) {
    return sex == 1 ? '600060731' : '600060732'
  }
  
  return ''
}

// 判断当前标签是否为 kids 标签【】
export const isFilterByKids = id => {
  return [
    '600060714', 
    '600060717',
    '600060719', 
    '600060720',
    '600060724', 
    '600060725',
    '600060731', 
    '600060732'
  ].includes(id)
}
