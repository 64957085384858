<template>
  <div v-expose="item.exposeData">
    <TagButtonContainer
      :active="cccImageData.active"
      :styles="cccImageData.styles"
      @click="onClick"
    >
      <img
        :src="cccImageData.src"
        :style="cccImageData.imgStyles"
      />
    </TagButtonContainer>
  </div>
</template>

<script setup>
import { toRef, inject } from 'vue'

// components
import TagButtonContainer from '../../TagButtonContainer/index'

// hooks
import { useCccImageData } from './hooks.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'

const emits = defineEmits(['click', 'change'])
const props = defineProps({ item: { type: Object, default: () => ({}) } })

const queryParams = inject('queryParams', {})
const fhContext = inject('fhContext', {})
const googleContext = inject('googleContext', {})
const cccImageData = useCccImageData(toRef(props, 'item'), queryParams)
const onClick = () => {
  emits('click', props.item)
  emitCloseDropdown()
  const item = props.item
  const { value, tagIndex } = props.item
  const isCancel = Number(value === queryParams.value?.tag_ids)
  const tag_ids = cccImageData.value.active ? '' : cccImageData.value.value

  analysis.clickCloudTag({
    tagId: value,
    tagCate: 'label',
    tagIndex: tagIndex + 1,
    isCancel,
    facet: fhContext?.value?.tag_id,
    gfilter: googleContext?.value?.gfilter,
    isPicNavCate: false
  })
  emits('toStickyByClick')
  emits('change', {
    item,
    params: {
      source: 'cloudTag',
      source_status: tag_ids ? 1 : 0,
      mall_tag: props.item?.mall_tag,
      tag_ids
    }
  })
}
</script>

<style scoped lang="less">
img {
  height: 12px;
}
</style>
