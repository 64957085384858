<!-- 标签滑动 - swiper -->
<template>
  <div class="cloud-tags swiper__wrap">
    <swiper-container 
      ref="swiperEl"
      class="swiper__container"
      init="false"
      destroy-on-disconnected="false"
    >
      <swiper-slide
        v-for="(tag) in firstScreenItems"
        :key="`${tag.tagItemType}_${tag.value}`"
        class="swiper__slide"
      >      
        <TagItem
          :key="`${tag.tagItemType}_${tag.value}`"
          :item="tag"
          :is-auto-sticky="true"
          :tag-all-list="tagAllList"
          :daily-tags="dailyTags"
          :cloud-tags="cloudTags"
          :price-tags="priceTags"
          :pic-nav-cate-tags="picNavCateTags"
          :cate-tags="cateTags"
          :tsp-tags="tspTags"
          :kid-tags="kidTags"
          :attr-tags="attrTags"
          :is-show-kid-tag-popover="isShowKidTagPopover"
          @toStickyByClick="toStickyByClick"
          @beforeOpen="toStickyByClick"
          @change="onChange"
          @click="onClick"
          @saveCurrAttr="saveCurrAttr"  
          @visibleChange="onVisibleChange"
        />
      </swiper-slide>

      <swiper-slide
        v-for="(tag) in secondScreenItems"
        :key="`${tag.tagItemType}_${tag.value}`"
        class="swiper__slide"
      >      
        <TagItem
          :key="`${tag.tagItemType}_${tag.value}`"
          :item="tag"
          :is-auto-sticky="true"
          :tag-all-list="tagAllList"
          :daily-tags="dailyTags"
          :cloud-tags="cloudTags"
          :price-tags="priceTags"
          :pic-nav-cate-tags="picNavCateTags"
          :cate-tags="cateTags"
          :tsp-tags="tspTags"
          :attr-tags="attrTags"
          :is-show-kid-tag-popover="isShowKidTagPopover"
          @toStickyByClick="toStickyByClick"
          @beforeOpen="toStickyByClick"
          @change="onChange"
          @click="onClick"
          @saveCurrAttr="saveCurrAttr"    
          @visibleChange="onVisibleChange"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script setup>

import { ref, computed, onMounted, watch, provide, inject, nextTick } from 'vue'
import TagItem from '../TagItem/index.vue'
import { runDelay } from 'public/src/js/utils/index.js'
import { register } from 'swiper/element'
import { emitUpdateDropdownPanelHeight } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
typeof window !== 'undefined' && register()

const props = defineProps({
  tagAllList: { type: Array, default: () => [] },
  dailyTags: { type: Array, default: () => [] },
  cloudTags: { type: Array, default: () => [] },
  priceTags: { type: Array, default: () => [] },
  picNavCateTags: { type: Array, default: () => [] },
  cateTags: { type: Array, default: () => [] },
  tspTags: { type: Array, default: () => [] },
  attrTags: { type: Array, default: () => [] },
  kidTags: { type: Array, default: () => [] },
  isShowKidTagPopover: { type: Boolean, default: false },
})

const emits = defineEmits(['onSwiperInit', 'onSwiperSlideEnd', 'autoSticky'])

const swiperEl = ref() 
const swiperInstance = ref(null)
const filterBar = inject('filterBar', {})
const catInfo = inject('catInfo', {})
const queryParams = inject('queryParams', {})
const constantData = inject('constantData', {})
const activeTagId = ref(queryParams.value?.tag_ids || queryParams.value?.pic_cate_id)
const openDelay = computed(() => {
  const { isStickyPicTopNav, isStickyPicksPic } = filterBar.value?.filterConfig || {}

  return (isStickyPicTopNav || isStickyPicksPic) ? 350 : 0
})
provide('openDelay', openDelay)

const swiperComputedSlideIndex =  () => {
  let beginIndex = null
  let endIndex = null
  if (!swiperInstance.value) return

  if (!swiperInstance.value.slidesSizesGrid) return { beginIndex, endIndex }

  swiperInstance.value.slidesSizesGrid.reduce((acc, cur, index) => {
    acc += cur
    let resetAcc = acc - Math.abs(swiperInstance.value.translate)
    // 有位移，减去位移的长度，获取下一个tab的索引，即resetAcc 大于0
    if (resetAcc <= 0) return acc
    if (beginIndex === null) beginIndex = index
    // 贪婪判断 （只要当前tab有一部分（最左边开始）在屏幕上即算曝光）
    if (resetAcc - cur <= swiperInstance.value.width) endIndex = index
    return acc
  }, 0)
  return {
    beginIndex,
    endIndex
  }
}

const swiperInitialize = () => { 
  if (swiperInstance.value?.initialized) {
    swiperUpdate()
    return
  }
  const swiperParams = {
    slidesPerView: 'auto',
    observer: true,
    initialSlide: 0,
    centeredSlides: true,
    slideToClickedSlide: true,
    setWrapperSize: true,
    on: {
      init: async (instance) => {
        instance.setTranslate(0)
        await nextTick()
        const { beginIndex, endIndex } = swiperComputedSlideIndex()
        emits('onSwiperInit', beginIndex, endIndex)
        $(window).scroll()
      },
      transitionStart (instance) {
        let translateX
        let minTranslateX = 0
        let maxTranslateX
        if (typeof maxTranslateX === 'undefined') {
          const translateX =  $(instance.wrapperEl).css('width').replace('px', '') - instance.width
          maxTranslateX = translateX > 0 ? translateX : 0
        }

        if (constantData.value?.GB_cssRight) {
          if (instance.translate > maxTranslateX) translateX = -maxTranslateX
          if (instance.translate < minTranslateX) translateX = minTranslateX
        } else {
          minTranslateX = -maxTranslateX
          maxTranslateX = 0
          if (instance.translate > maxTranslateX) translateX = maxTranslateX
          if (instance.translate < minTranslateX) translateX = minTranslateX
        }

        if (typeof translateX !== 'undefined') {
          instance.setTranslate(translateX)
        }
      },
      slideChangeTransitionEnd: async () => {
        await nextTick()
        const { beginIndex, endIndex } = swiperComputedSlideIndex()
        emits('onSwiperSlideEnd', beginIndex, endIndex)
        $(window).scroll()
      }
    },
  }

  Object.assign(swiperEl.value, swiperParams)
  swiperEl.value.initialize()
  swiperInstance.value = swiperEl.value.swiper
}

const swiperUpdate = async () => { 
  await nextTick()
  swiperInstance.value?.update()
  const index = props.tagAllList.findIndex(item => item.value === activeTagId.value)
  swiperInstance.value?.slideTo(index > -1 ? index : 0, 300)
  emitUpdateDropdownPanelHeight(300)
}

const saveCurrAttr = (id, type) => emits('saveCurrAttr', id, type)
const onVisibleChange = flag => emits('visibleChange', flag)

watch(catInfo, async () => {
  const { requestType } = catInfo.value || {}
  if (requestType === 'nextpage') return
  if (['firstload', 'switchTab'].includes(requestType)){
    activeTagId.value = queryParams.value?.tag_ids || queryParams.value?.pic_cate_id || ''
  }

  await nextTick()
  runDelay(swiperUpdate)
})

const toStickyByClick = () => emits('toStickyByClick')
const onChange = ({ item, params }) => {
  activeTagId.value = item.value
  emits('change', { item, params })
}
const onClick = item => emits('click', item)


const timer = ref(0)
const secondScreenItemsVisible = ref(false)
const firstScreenItems = computed(() => props.tagAllList.slice(0, 6))
const secondScreenItems = computed(() => secondScreenItemsVisible.value ? props.tagAllList.slice(6) : [])
const setScendScreenItemsVisible = () => {
  clearTimeout(timer.value)
  timer.value = setTimeout(async () => {
    clearTimeout(timer.value)
    secondScreenItemsVisible.value = true
    await nextTick()
    runDelay(swiperInitialize)
  }, 1500)
}

watch(() => props.tagAllList, () => {
  if (
    catInfo.value?.requestType === 'firstload' 
    || catInfo.value?.requestType === 'switchTab'
  ) {
    secondScreenItemsVisible.value = false
    setScendScreenItemsVisible()
  }
})

onMounted( async () => {
  setScendScreenItemsVisible()
})
</script>

<style scoped lang="less">
@swiperContainerHeight: 51px;
@swiperSlideHeight: 39px;
@swiperLabelHeight: 27px;

.swiper {
  &__wrap {
    background: #fff;
    padding: 12px 6px 0 6px;
    height: @swiperContainerHeight;
  }

  &__container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: @swiperContainerHeight;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
  }
  &__slide {
    width: auto;
    height: @swiperSlideHeight;
    margin-right: 8px;
    flex-shrink: 0;
    display: block;
  }
}

.cloud-tags {
  /deep/ .tag-dropdown__container{
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    width: auto;
    font-size: 12px;
    white-space: nowrap;
    height: @swiperSlideHeight;
    border: 1px solid rgba(255,255,255,0);
    color: #666;
  }

  /deep/ .dropdown-label__container {
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  /deep/ .dropdown-label__content {
    height: @swiperLabelHeight;
    padding: 6px 8px;
    background: #f6f6f6;
  }

  /deep/ .dropdown-label__content-visible {
    color: #000 /* rw: #FC4070 */;
    border: 1px solid #000;
    /* rw:begin */
    border: 1px solid #FC4070;
  }

  /deep/ .dropdown-label__content-selected {
    color: #000 /* rw: #FC4070 */;
    border: 1px solid #000;
    /* rw:begin */
    border: 1px solid #FC4070;
  }
}
</style>
