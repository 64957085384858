<template>
  <div
    v-show="showFooter"
    class="kids-modal__footer"
  >
    <svg
      v-if="childId"
      class="remove-btn"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      @click="onRemoveBtnClick"
    >
      <rect
        opacity="0.01"
        width="32"
        height="32"
        fill="white"
      />
      <path
        d="M24.8889 9.77777V27.5556H7.11111V9.77777"
        stroke="#BF4123"
        stroke-width="2"
      />
      <path
        d="M12.8889 21.3333V15.1111"
        stroke="#BF4123"
        stroke-width="2"
      />
      <path
        d="M19.1111 21.3333V15.1111"
        stroke="#BF4123"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.11111 8.8889H28.8889H3.11111Z"
        fill="#BF4123"
      />
      <path
        d="M3.11111 8.8889H28.8889"
        stroke="#BF4123"
        stroke-width="2"
      />
      <path
        d="M10.2222 8.8889L12.1481 4.44446H16H19.8519L21.7778 8.8889"
        stroke="#BF4123"
        stroke-width="2"
      />
    </svg>

    <s-button
      class="save-btn"
      :type="['primary']"
      :loading="loading"
      @click="handleSave"
    >
      {{ language.SHEIN_KEY_PWA_32897 }}
    </s-button>
    <RemoveModal
      v-if="childId"
      v-model="showRemoveModal"
      :child-id="childId"
      @delete-success="onDeleteSuccess"
    />
  </div>
</template>

<script>

import { defineComponent, ref, inject } from 'vue'

import { Button as SButton } from '@shein/sui-mobile'
import RemoveModal from './RemoveModal.vue'

export default defineComponent({
  name: 'ModalFooter',
  components: {
    SButton,
    RemoveModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: [String, Number],
      default: '',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
  },
  setup (props, { emit }) {

    const {
      getLanguage,
    } = inject('KIDS_CONTEXT')

    const handleSave = () => {
      emit('save')
    }

    const showRemoveModal = ref(false)

    const onRemoveBtnClick = () => {
      showRemoveModal.value = true
    }

    const onDeleteSuccess = () => {
      emit('delete-success')
    }

    return {
      language: getLanguage(),
      showRemoveModal,
      handleSave,
      onRemoveBtnClick,
      onDeleteSuccess,
    }
  }
})
</script>

<style lang="less" scoped>
.kids-modal__footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-bottom: 12px;

  .remove-btn {
    margin-left: 12px;
  }

  .save-btn {
    margin-left: 12px;
    margin-right: 12px;
    flex-grow: 1;
  }
}
</style>
