<script lang="jsx">
import ActiveIcon from 'public/src/pages/components/FilterBar/components/ActiveIcon.vue'
import TagPopover from 'public/src/pages/components/FilterBar/components/popover/index.vue'

export default {
  name: 'TagButtonContainer',
  functional: true,
  inject: ['constantData'],
  render(_, { props, injections, slots, listeners }) {
    const { isDot = false, active, styles = {}, isShowPopover, popoverText, popoverShowTime } = props
    const { constantData = {} } = injections || {}
    const slotDefault = slots?.()?.default ?? null
    
    const content = isShowPopover 
      ? (
        <TagPopover 
          text={popoverText}
          showTime={popoverShowTime}
        >
          <div
            class="tag-cloud-tag__content"
            style={styles}
          >
            {slotDefault}
          </div>
        </TagPopover>
      ) 
      : (
        <div
          class="tag-cloud-tag__content"
          style={styles}
        >
          {slotDefault}
        </div>
      )

    return (
      <div
        class={[
          'tag-cloud-tag__container', 
          isDot && 'tag-cloud-tag__container-dot'
        ]}
        onClick={listeners.click}
      >
        {content}

        <ActiveIcon
          show={active}
          color={styles.color}
          cssRight={constantData?.GB_cssRight}
        />
      </div>
    )
  }
}
</script>

<style lang="less">
.tag-cloud-tag {
  &__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    height: 39px;
    border: 1px solid rgba(255, 255, 255, 0);
  }

  &__container-dot:after {
    content: '';
    position: absolute;
    top: 1px;
    right: .16rem;
    width: .16rem;
    height: .16rem;
    border-radius: 50%;
    background-color: @sui_color_guide;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    width: auto;
    font-size: 12px;
    padding: 0 12px;
    white-space: nowrap;
    height: 27px;
  }
}
</style>
