<template>
  <div class="kids-form">
    <NameInput
      v-model="nickname"
      @input="isNameChanged = true"
    />
    <div class="kids-form__error-msg">
      {{ nameErrorMsg }}
    </div>
    <div class="kids-form__item">
      <DateSelector
        :month="month"
        :year="year"
        @select="onSelect"
      />
      <div class="kids-form__error-msg">
        {{ birthdayErrorMsg }}
      </div>
    </div>
    <GenderRadio
      v-model="sex"
      @input="handleSexChange"
    />
  </div>
</template>

<script>

import { defineComponent, inject, ref } from 'vue'

import NameInput from './NameInput.vue'
import DateSelector from 'public/src/pages/components/date-selector/Container.vue'
import GenderRadio from './GenderRadio.vue'

export default defineComponent({
  name: 'KidsForm',
  components: {
    NameInput,
    DateSelector,
    GenderRadio,
  },
  props: {
    isModified: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {

    const { getLanguage } = inject('KIDS_CONTEXT')

    const {
      nickname,
      month,
      year,
      sex,
      handleDateSelect,
    } = inject('KIDS_FORM_DATA')

    const {
      nameErrorMsg,
      birthdayErrorMsg,
      checkBirthday,
    } = inject('KIDS_FORM_VALIDATE')

    const isNameChanged = ref(false)

    const onSelect = (data) => {
      handleDateSelect(data)
      checkBirthday()
    }

    const handleSexChange = (newSex) => {
      if (props.isModified || isNameChanged.value) {
        return
      }
      const language = getLanguage()
      nickname.value = String(newSex) === '2' ? language.SHEIN_KEY_PWA_32905 : language.SHEIN_KEY_PWA_32904
    }

    return {
      isNameChanged,
      nameErrorMsg,
      birthdayErrorMsg,
      nickname,
      month,
      year,
      sex,
      onSelect,
      handleSexChange,
    }
  }
})
</script>

<style lang="less" scoped>
.kids-form {
  padding-left: 12px;
  padding-right: 12px;

  &__item {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &__error-msg {
    color: #BF4123;
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
