<template>
  <s-dialog
    class="remove-modal"
    :visible="visible"
    :show-close="false"
    append-to-body
  >
    <template #top>
      <img
        class="remove-modal__header-img"
        src="//img.ltwebstatic.com/images3_ccc/2024/08/02/d4/1722590478bd468dd9614ed6acc56b447a2bc0828f.png"
      />
    </template>
    <template #title>
      {{ language.SHEIN_KEY_PWA_32972 || '' }}
    </template>
    <div class="remove-modal__desc">
      {{ language.SHEIN_KEY_PWA_32973 || '' }}
    </div>
    <template #footer>
      <div
        class="remove-modal__btn-group"
      >
        <s-button @click="handleCancel">
          <span>{{ language.SHEIN_KEY_PWA_32779 || 'CANCEL' }}</span>
        </s-button>
        <s-button
          class="delete-btn"
          :loading="loading"
          @click="handleDelete"
        >
          <span>{{ language.SHEIN_KEY_PWA_32778 || 'DELETE' }}</span>
        </s-button>
      </div>
    </template>
  </s-dialog>
</template>

<script>

import { defineComponent, computed, ref, inject } from 'vue'
import { Dialog as SDialog, Button as SButton, Toast as $toast } from '@shein/sui-mobile'

import { deleteChildInfo } from './api'

export default defineComponent({
  name: 'RemoveModal',
  components: {
    SDialog,
    SButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    childId: {
      type: [String, Number],
      default: '',
    },
  },
  setup (props, { emit }) {

    const {
      getLanguage,
    } = inject('KIDS_CONTEXT')

    const visible = computed({
      get: () => props.value,
      set: (val) => {
        emit('input', val)
      }
    })

    const handleCancel = () => {
      visible.value = false
    }

    const loading = ref(false)

    const handleDelete = async () => {
      loading.value = true
      const data = await deleteChildInfo({ child_id: props.childId })
      if (String(data.code) === '0') {
        visible.value = false
        emit('delete-success')
      } else {
        $toast(getLanguage().SHEIN_KEY_PWA_32889)
      }
      loading.value = false
    }

    return {
      loading,
      visible,
      language: getLanguage(),
      handleCancel,
      handleDelete,
    }
  }
})
</script>

<style lang="less" scoped>
.remove-modal {
  &__desc {
    margin-top: 12px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }

  &__btn-group {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  /deep/ .delete-btn.S-button {
    background: #BF4123;
    border: none;
    color: #fff;
  }
}

[mir="rtl"] .remove-modal__header-img {
  transform: rotateY(180deg);
}
</style>
