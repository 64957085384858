<template>
  <DropdownCommon
    :icon="icon"
    :label="label"
    :styles="styles"
    :loading="loading"
    :selected="selected"
    :openDelay="openDelay"
    :disabled="disabled"
    :close-on-click-modal="closeOnClickModal"
    @click="onClick"
    @loadSlide="onLoadSlide"
    @beforeOpen="onBeforeOpen"
    @beforeClose="onBeforeClose"
    @visibleChange="onVisibleChange"
  >
    <SelectMenu 
      :border-top="borderTop"
      :default-select-index="defaultSelectIndex"
      :menu-list="menuList"
      :loading="loading"
    />
  </DropdownCommon>
</template>

<script>
export default {
  name: 'DropdownMenu',
}
</script>

<script setup>
import DropdownCommon from './DropdownCommon.vue'
import SelectMenu from 'public/src/pages/components/filter_new/components/SelectMenu'

defineProps({
  icon: { type: String, default: '' },
  label: { type: String, default: '' },
  closeOnClickModal: { type: Boolean, default: true },
  styles: { type: Object, default: () => ({}) },
  selected: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  openDelay: { type: Number, default: 0 },
  // SelectMenu props
  defaultSelectIndex: { type: [String, Number], default: '' },
  menuList: { type: Array, default: () => [] },
  resetData: { type: Boolean, default: false },
  borderTop: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
})

const emits = defineEmits(['click', 'beforeOpen', 'beforeClose', 'visibleChange', 'loadSlide'])
const onClick = () => emits('click')
const onLoadSlide = () => emits('loadSlide')
const onBeforeOpen = () => emits('beforeOpen')
const onBeforeClose = () => emits('beforeClose')
const onVisibleChange = visible => emits('visibleChange', visible)
</script>
