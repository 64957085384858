<template>
  <TagPopover
    ref="refKidPopover"
    type="dropdown"
    :is-show="isShowPopover"
    :show-time="3000"
    @hide="onHidePopover"
  >
    <slot></slot>

    <template #text>
      <div class="kid-tags__popover">
        <div>
          <div
            ref="refLottieEl"
            class="kid-tags__icon"
          ></div>
        </div>

        <div class="kid-tags__text">
          {{ language.SHEIN_KEY_PWA_32965 }}
        </div>
      </div>
    </template>  
  </TagPopover>
</template>

<script setup>
import { ref, watch, computed, onMounted, onBeforeUnmount, nextTick } from 'vue'
import TagPopover from 'public/src/pages/components/FilterBar/components/popover/index.vue'
import { getIsShowKidsTagPopoverByCache } from 'public/src/pages/components/FilterBar/utils/kids.js'

const emits = defineEmits(['hide'])
const props = defineProps({
  isShow: { type: Boolean, default: false },
  item: { type: Object, default: () => ({}) },
  language: { type: Object, default: () => ({}) }
})

const refLottieEl = ref()
const animationInstance = ref()
const lottie = ref(null)
const lottieJson = ref(null)
const getLottieResult = async () => {
  if (lottie.value && lottieJson.value) {
    return Promise.resolve()
  }

  const [lottieWeb, lottieData] =  await Promise.all([
    import('lottie-web'),
    import('public/src/pages/components/FilterBar/CloudTags/components/TagItem/TagKids/lottie.json')
  ])

  // 使用局部变量存储结果
  const lottieModule = lottieWeb.default
  const lottieJsonData = lottieData.default

  // 一次性更新 ref 变量
  // eslint-disable-next-line require-atomic-updates
  lottie.value = lottieModule
  // eslint-disable-next-line require-atomic-updates
  lottieJson.value = lottieJsonData

  return Promise.resolve()
}
const showAnimation = async () => {
  await Promise.all([getLottieResult(), nextTick()])
  
  animationInstance.value = lottie.value?.loadAnimation({
    container: refLottieEl.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: lottieJson.value,
    name: 'kidPopoverIcon'
  })
}

const isShowByGlobCoupon = ref({ coupon: '', couponRevisit: '' }) // coupon/couponRevisit弹窗展示，则不展示气泡【public/src/pages/common/glob_popup_modal】
const isShowByUser = ref(true) // 根据用户是否操作判断是否展示，如用户点击qs/滚动页面后，不再展示
const isShowPopover = computed(() => {
  if (typeof window === 'undefined') return false

  if (!props.isShow) return false
  if (props.item.child_list?.length ) return false
  if (!Object.values(isShowByGlobCoupon.value).every(val => val === false)) return false
  if (!isShowByUser.value) return false

  return getIsShowKidsTagPopoverByCache()
})

const onGlobalPopModalQueue = () => {
  window._GB_PopUpModalEventCenter_.$on('global-pop-modal-queue', obj => {
    const { name, isWait } = obj
    if (!['coupon', 'couponRevisit'].includes(name)) return

    isShowByGlobCoupon.value = {
      ...isShowByGlobCoupon.value,
      [name]: isWait
    }
  })
}

watch(isShowPopover, (val) => {
  val && showAnimation()
})

const onTouchstart = () => {
  isShowByUser.value = false
}

watch(() => props.item, () => {
  isShowByUser.value = true
})

const onHidePopover = () => {
  isShowByUser.value = false
  emits('hide')
}
onMounted(() => {
  onGlobalPopModalQueue()
  window.addEventListener('touchstart', onTouchstart)
})

onBeforeUnmount(() => {
  lottie.value?.destroy()
  window.removeEventListener('touchstart', onTouchstart)
})
</script>

<style lang="less" scoped>
.kid-tags{
  &__popover {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    line-height: 14px;
  }
}
</style>

