
export const getMonthMap = (language) => {
  const monthAMap = {
    '01': language.SHEIN_KEY_PWA_32871,
    '02': language.SHEIN_KEY_PWA_32872,
    '03': language.SHEIN_KEY_PWA_32873,
    '04': language.SHEIN_KEY_PWA_32874,
    '05': language.SHEIN_KEY_PWA_32875,
    '06': language.SHEIN_KEY_PWA_32876,
    '07': language.SHEIN_KEY_PWA_32877,
    '08': language.SHEIN_KEY_PWA_32878,
    '09': language.SHEIN_KEY_PWA_32879,
    '10': language.SHEIN_KEY_PWA_32880,
    '11': language.SHEIN_KEY_PWA_32881,
    '12': language.SHEIN_KEY_PWA_32882,
  }
  return {
    monthAMap,
    monthBMap: Object.fromEntries(Object.entries(monthAMap).map(([k, v]) => [v, k])),
  }
}
