<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.16667 2.83325V1.83325H6.16667V2.83325H9.83333V1.83325H10.8333V2.83325H13.8333V14.4999H2.16667V2.83325H5.16667ZM9.33333 7.66658H10.6667V6.66658H9.33333V7.66658ZM6.66667 10.6666H5.33333V9.66659H6.66667V10.6666ZM9.33333 10.6666H10.6667V9.66659H9.33333V10.6666ZM6.66667 7.66658H5.33333V6.66658H6.66667V7.66658ZM3.16667 13.4999V3.83325H12.8333V13.4999H3.16667Z"
      fill="black"
      fill-opacity="0.8"
    />
  </svg>
</template>

<script>

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DateIcon',
})
</script>
