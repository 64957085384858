<template>
  <div class="gender-radio">
    <div class="gender-radio__label">
      {{ language.SHEIN_KEY_PWA_32896 }}
    </div>
    <div class="gender-radio__content">
      <s-radio
        v-model="genderVal"
        label="1"
      >
        <span class="gender-radio__text">
          {{ language.SHEIN_KEY_PWA_32904 }}
        </span>
      </s-radio>
      <s-radio
        v-model="genderVal"
        label="2"
      >
        <span class="gender-radio__text">
          {{ language.SHEIN_KEY_PWA_32905 }}
        </span>
      </s-radio>
    </div>
  </div>
</template>

<script>

import { defineComponent, computed, inject } from 'vue'
import { Radio as SRadio } from '@shein/sui-mobile'

export default defineComponent({
  name: 'GenderRadio',
  components: {
    SRadio,
  },
  props: {
    value: {
      type: String,
      default: '2',
    },
  },
  setup (props, { emit }) {

    const {
      getLanguage,
    } = inject('KIDS_CONTEXT')

    const genderVal = computed({
      get: () => props.value,
      set: (val) => {
        emit('input', val)
      }
    })

    return {
      language: getLanguage(),
      genderVal,
    }
  }
})
</script>

<style lang="less" scoped>
.gender-radio {

  padding-left: 12px;

  &__label {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 6px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__text {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
