<template>
  <div class="kids-modal-header">
    <img
      class="kids-modal__bg-img"
      :src="bgImgSrc"
    />
    <Icon
      class="kids-modal__close-btn"
      name="sui_icon_close_16px" 
      size="16px"
      @click="closeModal"
    />
    <div class="kids-modal__header-content">
      <div class="kids-modal__header-title">
        {{ isBirthdayOver ? language.SHEIN_KEY_PWA_32975 : language.SHEIN_KEY_PWA_33092 }}
      </div>
      <div class="kids-modal__header-desc">
        {{ isBirthdayOver ? language.SHEIN_KEY_PWA_33020 : language.SHEIN_KEY_PWA_32965 }}
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

export default defineComponent({
  name: 'KidsModalHeader',
  components: {
    Icon,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    isBirthdayOver: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const bgImgSrc = computed(() => {
      return props.isBirthdayOver ?
        '//img.ltwebstatic.com/images3_ccc/2024/08/02/6c/17225906031c23d427a44a2101f7726ef7b7ce26fb.png' :
        '//img.ltwebstatic.com/images3_ccc/2024/08/02/d1/17225905440f61a630df57be4fd6df0cb30401859f.png'
    })

    const closeModal = () => {
      emit('close-modal')
    }

    return {
      bgImgSrc,
      closeModal,
    }
  }
})
</script>

<style lang="less" scoped>
.kids-modal {
  
  &-header {
    min-height: 3.25rem;
    margin-bottom: 12px;
  }

  &__bg-img {
    width: 100%;
    height: 3.25rem;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__close-btn {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  &__header-content {
    position: relative;
    height: 3.25rem;
    line-height: 1.4;
    padding-left: 16px;
    max-width: 5.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__header-title {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__header-desc {
    color: rgba(0, 0, 0, 0.60);
    font-size: 12px;
    font-weight: 400;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

[mir="rtl"] {
  .kids-modal__bg-img {
    transform: rotateY(180deg);
  }
}
</style>
