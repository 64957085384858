
import schttp from 'public/src/services/schttp'

export const addChildInfo = async (params) => {
  return await schttp({
    url: '/api/member/child/add',
    method: 'POST',
    data: {
      child_list: params.child_list,
    }
  })
}

export const deleteChildInfo = async (params) => {
  return await schttp({
    url: '/api/member/child/delete',
    method: 'POST',
    data: {
      child_id: params.child_id,
    }
  })
}

export const modifyChildInfo = async (params) => {
  return await schttp({
    url: '/api/member/child/modify',
    method: 'POST',
    data: {
      child_list: params.child_list,
    }
  })
}
