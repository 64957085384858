<template>
  <s-drawer
    :visible="visible"
    max-size="80vh"
    direction="btt"
    append-to-body
    @close-from-mask="closeModal"
  >
    <div
      class="kids-modal"
    >
      <KidsModalHeader
        :language="language"
        :is-birthday-over="isBirthdayOver"
        @close-modal="closeModal"
      />
      <KidsForm :is-modified="isModified" />
      <ModalFooter
        :child-id="childInfo.child_id"
        :show-footer="showFooter"
        @delete-success="onDeleteSuccess"
        @save="handleSave"
      />
    </div>
  </s-drawer>
</template>

<script>
/**
 * @file kids信息采集弹窗
 */

import { defineComponent, onMounted, ref, provide, computed } from 'vue'

import { Drawer as SDrawer, Toast as $toast } from '@shein/sui-mobile'

import KidsModalHeader from './ModalHeader.vue'
import KidsForm from './KidsForm.vue'
import ModalFooter from './ModalFooter.vue'

import { useKidsFormData, useKidsValidate, useAndroidKeyboard } from './hooks'
import { addChildInfo, modifyChildInfo } from './api'

export default defineComponent({
  name: 'KidsFormModal',
  components: {
    SDrawer,
    KidsModalHeader,
    KidsForm,
    ModalFooter,
  },
  props: {
    childInfo: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    isModified: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    provide('KIDS_CONTEXT', {
      getLanguage: () => props.language,
    })

    const isBirthdayOver = computed(() => {
      if (!props.isModified) {
        return false
      }
      const currentYear = new Date().getFullYear()
      const [selectedYear, selectedMonth] = props.childInfo.birthday.split('-')
      if (currentYear - Number(selectedYear) > 16) {
        return true
      }
      if (currentYear - Number(selectedYear) === 16) {
        const currentMonth = new Date().getMonth() + 1
        return selectedMonth <= currentMonth
      }
      return false
    })

    const {
      showFooter,
    } = useAndroidKeyboard()
    const { getFormData, initFormData } = useKidsFormData({ language: props.language, isModified: props.isModified })
    const { checkForm } = useKidsValidate({
      getFormData,
      language: props.language,
      isBirthdayOver,
    })

    const visible = ref(false)

    const closeModal = () => {
      visible.value = false

      emit('close')
    }

    onMounted(() => {
      initFormData(props.childInfo)
      visible.value = true
    })

    const loading = ref(false)

    const handleSave = async () => {
      if (!checkForm()) {
        $toast(props.language.SHEIN_KEY_PWA_32893)
        return
      }
      loading.value = true
      const formData = getFormData()
      const birthday = `${formData.year}-${formData.month}`
      const childInfo = {
        child_id: props.childInfo.child_id || undefined,
        birthday,
        nickname: formData.nickname,
        sex: Number(formData.sex),
      }
      const params = {
        child_list: [childInfo],
      }
      const api = props.isModified ? modifyChildInfo : addChildInfo
      const data = await api(params)
      loading.value = false
      if (String(data.code) === '0') {
        $toast(props.language.SHEIN_KEY_PWA_32974)
        visible.value = false
        emit('submit', {
          ...childInfo,
          ...(props.isModified ? {} : { child_id: data.info.child_id_list[0] }),
        })
      } else {
        $toast(props.language.SHEIN_KEY_PWA_32889)
      }
    }

    const onDeleteSuccess = () => {
      visible.value = false
      emit('delete-success')
    }

    return {
      showFooter,
      isBirthdayOver,
      visible,
      loading,
      closeModal,
      handleSave,
      onDeleteSuccess,
    }
  }
})
</script>

<style lang="less" scoped>
.kids-modal {
  width: 100vw;
  height: 80vh;
  background-color: #fff;
  position: relative;
}
</style>
