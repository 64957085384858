<template>
  <DropdownCommon
    :icon="icon"
    :label="label"
    :styles="styles"
    :loading="loading"
    :selected="selected"
    :openDelay="openDelay"
    :close-on-click-modal="closeOnClickModal"
    @click="onClick"
    @loadSlide="onLoadSlide"
    @beforeOpen="onBeforeOpen"
    @beforeClose="onBeforeClose"
    @visibleChange="onVisibleChange"
  >
    <RangeChange
      :sum="sum"
      :range="range"
      :language="language"
      :close-bottom-left="closeBottomLeft"
      :border-top="borderTop"
      :loading="loading"
    />
  </DropdownCommon>
</template>

<script>
export default {
  name: 'DropdownPrices',
}
</script>

<script setup>
import DropdownCommon from './DropdownCommon.vue'
import RangeChange from 'public/src/pages/components/filter_new/components/RangeChange.vue'

defineProps({
  icon: { type: String, default: '' },
  label: { type: String, default: '' },
  loading: { type: Boolean, default: false },
  closeOnClickModal: { type: Boolean, default: true },
  styles: { type: Object, default: () => ({}) },
  selected: { type: Boolean, default: false },
  openDelay: { type: Number, default: 0 },
  // RangeChange props
  sum: { type: Number, default: 0 },
  range: { type: Object, default: () => ({}) },
  language: { type: Object, default: () => ({}) },
  closeBottomLeft: { type: Boolean, default: false },
  borderTop: { type: Boolean, default: false },
})


const emits = defineEmits(['click', 'beforeOpen', 'beforeClose', 'visibleChange', 'loadSlide'])
const onClick = () => emits('click')
const onLoadSlide = () => emits('loadSlide')
const onBeforeOpen = () => emits('beforeOpen')
const onBeforeClose = () => emits('beforeClose')
const onVisibleChange = visible => emits('visibleChange', visible)
</script>
